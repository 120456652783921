@import 'src/containers/app/styles/variables';

.cv__section-title {
    font-size: 18px;

    > .anticon {
        margin-right: 8px;
        padding: 4px;
        border-radius: 100%;
        background-color: color(cinderella);
    }
}

.cv__section-body {
    width: 100%;
    max-width: 520px;
    padding: 0 0 20px 35px;
    margin: 0;
    list-style-type: none;
    white-space: pre-wrap;

    &--wide {
        max-width: none;
    }
}

.cv__list-item {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    &--column {
        flex-direction: column;
    }

    > h5 {
        margin: 0;
        font-size: inherit;
    }

    > p {
        margin: 0;
    }
}

.cv__experience-item {
    margin-top: 20px;
}

.cv__experience-list {
    list-style-type: none;
    padding: 0;
}

p.cv__reference-text {
    margin-top: 8px;
    font-style: italic;
}

/* PRINTABLE CV STYLES */

.printable-cv {

    display: none;

    .printable-cv-empty-section {
        padding: 20px;
        background: #FEF5F1;
    }
    
    .printable-cv-description {
        white-space: pre-wrap;
        background: #FEF5F1;
        padding: 20px;
        border-radius: 6px;
    }

    .cv__section-body {
        margin: 0;
        padding: 0;
        max-width: none!important;
    }
   
}

.printable-cv-section {
    margin-top: 30px;

    .section-list {
        list-style: none;
        padding: 0;
        margin: 0;
        background: #FEF5F1;

        .section-list-item--block,
        .section-list-item {
            padding: 20px;
            &:nth-child(even) {
                background-color: rgba(0,0,0,0.02);
            }
        }

        .section-list-item-content {
            display: flex;
            justify-content: space-between;
            max-width: auto;

        }

        .ant-avatar {
            margin-right: 10px;
        }
    }

    .cv-left-column {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .title {
            font-weight: bold;
            margin: 0;
        }

        .description {
            font-style: italic;
        }

        .mp-skill-tag {
            margin-top: 0;
        }
    }

    .type {
        margin: 0;
    }


    .mp-cv-medpeople-experience {
        display: flex;
    }

    .mp-cv-logo {
        height: 38px;
        width: 38px;
        margin-right: 10px;
        background-image: url(~assets/medpeople_symbol_brand_color.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .cv-mp-experiences {
        margin-left: 32px;
        border-left: 4px solid rgba(0,0,0,0.6);
        width: auto;
        border-radius: 0;
    }

    .section-list-item,
    .section-list-item--block {
        break-inside: avoid;
    }
}