/* global styles */

@import "~tailwindcss/base.css";
@import "~tailwindcss/components.css";
@import "~tailwindcss/utilities.css";
//@import 'rc-tooltip/assets/bootstrap.css';
@import 'fonts/roboto';
@import 'links';
@import 'variables';
@import 'datepicker2';

.group:after {
  content: "";
  display: table;
  clear: both;
}

.inline-spinner {
  color: blue;
  font-size: 18px;
  display: inline-flex;
  float: left;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  .fa-spin {
    animation-duration: .5s!important;
  }
}

body, html {
    font-family: 'Source Sans Pro', sans-serif;
    background: #f9f9f9;
}



h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
  margin: 0;
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

.sider.ant-layout-sider {
    height: 100vh;
    left: 0;
    overflow: auto;
    position: fixed;
}

.layout { 
    padding-left: $sidebar-width;
}

.content-card {
  background: #FFF;
  padding: 40px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  border-radius: 4px;

}

.content-card-centered {

 @extend .content-card;
 max-width: 1200px;
 margin: 40px auto;
  
}

.content-card-full {

  @extend .content-card;
  max-width: 100%;
  margin: 40px auto;
  overflow: scroll;

}

.content-card-inline {

  @extend .content-card;
  margin: 10;
  margin-bottom: 0;
  margin-right: 0;

}

.table-background{
  overflow: scroll;
  background-color: #FFF;
}