@import 'src/containers/app/styles/variables';

.mpa-employer-section {
    margin-bottom: 40px;    

    .mp-select__trigger {
        min-width: 200px;
    }

    &__item {
        margin-bottom: 40px;
    }

    &__title {
        margin-bottom: 10px;
    }

    button {
        &:disabled {
            opacity: .2;
        }
    }

    .activate-employer-form__section__status-description {
        padding: 20px;
        background-color: color(mine-shaft);
        color: color(snow);
        border-radius: 4px;
        max-width: 450px;
    }

}