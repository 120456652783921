
.password-label {
    text-align: center;
}

.credentials-form-group {
    margin-top: 40px;
}

.mpa-users-top {
    padding: 20px 40px 20px 20px;
    text-align: right;
    background: #f9f9f9;

}
