

.mpa-replace-consultant__warning {
    background: #f54141;
    color: #FFF;
    padding: 40px;
    display: flex;
    font-size: 14px;
    align-items: center;
    h2 {
        font-size: 16px;
    }
}


.mpa-replace-consultant__warning-icon {
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
}

.mpa-replace-consultant__content {
    padding: 40px;
}

.mpa-replace-consultant__shift {
    background: #f3f3f3;
    padding: 20px;
    margin-bottom: 20px;
}

.mpa-replace-consultant__actions {
    padding: 40px;
    text-align: center;
}

.mpa-replace-consultant__success {
    padding: 100px 40px;
    text-align: center;
}