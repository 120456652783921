@import 'src/containers/app/styles/variables';


.mp-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  transform: translateX(0) translateZ(0);
  transition: transform .5s ease;
}

.mp-modal-wrapper--in {
  transform: translateX(100%) translateZ(0);
  .mp-modal__container, .mp-modal {
    animation: none!important;
    opacity: 1!important;

  }
}

.mp-modal-wrapper--out {
    transform: translateX(-100%) translateZ(0);
}

.mp-modal__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  z-index: 1000;
  //animation: modal-container-mount .4s ease;
  will-change: opacity;
  animation-fill-mode: forwards;
  overflow: scroll;
  height: 100%;
}

.mp-modal {
  width: 760px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.3);
  margin: 100px auto;
  animation: modal-mount .25s ease-out;
  will-change: transform;
  animation-fill-mode: forwards;

  border-radius: 6px;
  overflow: visible;
}

.mp-modal__title {
  padding: 20px;
  border-bottom: 1px solid #f3f3f3;
  font-size: 22px;
  font-weight: 600;
}

.mp-modal__close {
  float: right;
  margin-top: 2px;
  color: #CCC;
  cursor: pointer;
  &:hover {
    color: inherit;
  }
}

.mp-modal__content {
  padding: 20px 40px;
}

@keyframes modal-container-mount {
  0% {
    background: rgba(0,0,0,0)
  }
  100% {
    background: rgba(0,0,0,0.7);
  }
}

@keyframes modal-mount {
  0% {
    transform: scale(0.9);
    box-shadow: 0 1px 5px rgba(0,0,0,0.0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 1px 5px rgba(0,0,0,0.3);
  }
}