@import 'src/containers/app/styles/variables';

.mpa-employer-top {
    background: #f4f4f7;
    border-bottom: 1px solid #EEE;
    padding: 40px 80px;
    padding-top: 120px;

    &__title {
        display: flex;
        margin-bottom: 0;
    }

    &__employer-automotive-status {

        background-color: color(cloud-burst);

    }
    
    &__employer-type,
    &__employer-status {
        font-weight: 600;
        font-size: 12px;
        margin-right: 9px;
        margin-top: 15px;
        padding: 6px 10px;
        border-radius: 4px;
        margin-bottom: 3px;

        &--is-activated {
            background-color: color(shamrock);
        }

        &--is-pending {
            background-color: color(gold);
        }

        &--is-visible {
            background-color: color(web-orange);
        }

        &--is-banned {
            background-color: color(black);
        }

        &--is-removed {
            background-color: color(roman);
        }

    }

    &__employer-department-types {
        margin-bottom: 5px;
        margin-top: 10px;
    }

    &__employer-department-types__type {
        padding: 6px 10px;
        border-radius: 4px;
        margin-right: 10px;
        font-weight: 600;

        &.secondary {
            background-color: color(amethyst-smoke);
        }

        &.primary {
            background-color: color(portage);
        }
    }
}