$sidebar-width: 5rem;
$sidebar-color: #25252f;
$main-color: #5b53f9;
$text-color: #333;
$search-bar-height: 4rem;

// Names generated by: 
// http://chir.ag/projects/name-that-color/

// Use function color(colorname) to pick a color

@function color($key) {
    
    @if map-has-key($colors, $key) {

      @return map-get($colors, $key);
      
    }
  
    @warn "Unknown `#{$key}` in $colors.";
    @return null;

}

$colors: (
    portage: #8a84fb,
    cornflower-blue: #886bf2,
    biloba-flower: #b698ef,
    web-orange: #ffa500,
    gold: #ffd400,
    supernova: #ffcd00,
    gin-fizz: #fffAE3,
    shamrock: #36d671,
    atlantis: #7dda3c,
    green: #00ff00,
    black: #000,
    snow: #fff,
    titan-white: #f9f9ff,
    gallery: #eee,
    selago: #e3eefb,
    whisper: #f5f5f9,
    concrete: #f3f3f3,
    silver: #ccc,
    alabaster: #f9f9f9,
    athens-gray: #f4f4f7,
    alto: #ddd,
    cloud-burst: #243150,
    east-bay: #3d4077,
    amethyst-smoke: #9793b1,
    regent-gray: #7e8fa5,
    dusty-gray: #999,
    dove-gray: #666,
    mine-shaft: #333,
    boulder: #777,
    turquoise: #35e6b8,
    blue-romance: #dbf9e4,
    highland: #689669,
    cinderella: #fde1e1,
    gray-suit: #b7b6c7,
    santas-grey: #9ea1b5,
    roti: #caaa49,
    chamois: #ecdba8,
    fruit-salad: #5fa94e,
    sugar-cane: #f6fff6,
    madang: #c8f5c8,
    early-dawn: #fff9ec,
    roman: #d9534f,
    french-rose: #ef5886,
    bittersweet: #ff6e6e,
    spindle: #c2c0ec,
    link-water: #f7f7fd,
    mischka: #cfcfdc,
);

$primary-color: color(portage);

$text-color: color(east-bay);
$text-color-light: color(amethyst-smoke);

$primary-color-gradient: linear-gradient($primary-color, darken($primary-color, 5));
