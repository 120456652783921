.employer-attests {
    flex: auto;
    padding: 40px;

    .heading {
        font-size: 2rem;
    }

    .empty-list {
        font-size: 1rem;
    }

    .employer {
        margin-bottom: 6.25rem;

        .heading {
            font-size: 1.25rem;
            margin-bottom: 0;
        }

        .header {
            align-items: center;
            display: flex;
            margin: 2.5rem 0 1.25rem;

            .link {
                font-size: 1rem;
                margin-left: .75rem;
            }
        }

        .column {
            vertical-align: middle;
        }
    }
}
