@import 'src/containers/app/styles/variables';

.mpa-tags-list {
    margin: 20px 0 0;
    &--small {
        margin-top: 5px;
        .mpa-tags-list__add-trigger {
            font-size: 14px;
            padding: 2px 10px;
            font-size: 12px;
        }
    }
}

.mpa-tags-list__add-trigger {

    position: relative;
    color: #999; //$main-color;
    display: inline-block;
    padding: 3px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    transition: all .1s linear;
    background: rgba(0,0,0,0.04);
    border-radius: 20px;
    &:hover {
        background: $main-color;
        color: #FFF;
    }

}

.mpa-tags-list__add-tag {

    position: absolute;
    top: 100%;
    left: 0;
    width: 300px;
    background: #FFF;
    padding: 20px;
    border-radius: 4px;
    z-index: 2000;
    box-shadow: 0 2px 20px rgba(0,0,0,0.2);
    color: #666;
    font-size: 14px;

}

.mpa-tags-list__matches {

    margin-top: 10px;
    max-height: 400px;
    overflow: auto;

}

.mpa-tags-list__match {

    padding: 10px;
    color: #666;

    &:hover,
    &--selected {
        background: lighten($main-color, 30%);
        color: $main-color;
    }

}

.mpa-tags-list__create {

    padding: 10px;
    &:hover,
    &--selected {
        background: lighten($main-color, 30%);
        color: $main-color;
    }
    //background: lighten($main-color, 30%);
    //color: $main-color;

}