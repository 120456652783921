@import 'src/containers/app/styles/variables';

.mp-textarea {
    border: 1px solid color(gallery);
    padding: 20px;
    background: color(alabaster);
    color: $text-color;
    border-radius: 3px;
    resize: none;
    display: block;
    width: 100%;
    box-sizing: border-box;
    transition: all .1s ease;
    &:focus {
        //border-color: $primary-color;
        border-color: color(silver);
        background: color(snow);
    }

    &:disabled {
        background: color(gallery);
    }
}