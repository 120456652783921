

.mpa-add-shifts-list__error-row--booked {
    position: relative;
    background: rgba(0,0,0,0.02);
    td {
        text-decoration: line-through;
        opacity: 0.3;
    }
    td:nth-last-of-type(3),
    td:nth-last-of-type(2),
    td:last-of-type {
        opacity: 1;
    }
}

.mpa-add-shifts-list__error-row--error {
    background-color: rgba(255, 0, 0, 0.1)
}

.mpa-add-shifts-list__input-error {
    outline: 1px solid red;
}

.mpa-add-shifts-list__cell {
    padding-bottom: 19px;
    .mpa-add-shifts-list__copy {
        display: none;
    }
    &--error {
        padding-left: 5px;
        padding-bottom: 3px;
        outline: 1px solid red;
    }
    &--error-message {
        display: block;
        margin-top: 5px;
        padding-left: 10px;
        font-size: 12px;
        color: red;
    }
    &:hover {
        padding-bottom: 3px;
        .mpa-add-shifts-list__copy {
            display: block;
        }
    }
}