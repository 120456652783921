@import 'src/containers/app/styles/variables';

.consultant-container {
    flex: auto;
    margin-right: 400px;
    overflow: scroll;

    .mp-consultant {
        animation: consultant-enter .1s ease-out;
        animation-fill-mode: both;
    }
}

@keyframes consultant-enter {
    0% {
        opacity: 0;
        //transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        //transform: translateY(0px);
    }
}

.consultant-info {

    h5 {
        margin-top: 30px;
        &:first-child {
            margin-top: 0;
        }
    }
    h6 {
        margin-bottom: 5px;
        margin-top: 20px;
    }
}

.mpa-consultant-section {
    margin-bottom: 40px;
}

.mpa-intercom-link {
    color: #408ee6;
    margin-top: 5px;
    display: flex;
    align-items: center;
    span {
        margin-left: 5px;
    }
}

.mpa-intercom-logo {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    background-image: url(~assets/intercomlogo.png);
    background-size: 18px;
    margin-right: 5px;
}

.mpa-google-logo {
    width: 22px;
    height: 22px;
    background-image: url(~assets/googlelogo.png);
    background-position: center center;
    background-size: 18px;
    margin-right: 5px;
    border-radius: 22px;
    border: 1px solid #CCC;
}
                  
.mpa-linkedin-link {
    display: flex; 
    align-items: center;

    span {
        margin-left: 5px;
    }

}

.mpa-linkedin-logo {
    font-size: 24px;
    color: #0084bf;
    margin-right: 5px;
}

.consultant-preview-items {

    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #e9e9ef;
    border-bottom: 1px solid #DDD;
}

.consultant-preview-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    padding: 10px 0;
    position: relative;
    z-index: 2000;
    opacity: 0.6;


    &:hover {
        opacity: 1;
    }
    &--active {
        opacity: 1;
        &:after, &:before {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }


        &:after {
            border-color: rgba(238, 238, 238, 0);
            border-top-color: #e9e9ef;
            border-width: 15px;
            margin-left: -15px;
        }
        &:before {
            border-color: rgba(204, 204, 204, 0);
            border-top-color: #ccc;
            border-width: 16px;
            margin-left: -16px;
        }
    }
}

.consultant-preview-item-avatar {
    position: relative;
}

.consultant-preview-item-status {
    position: absolute;
    bottom: 0;
    right: -5px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: red;
}

.consultant__intent-list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    white-space: pre-wrap;
}

.consultant__intent-list-item {
    width: 100%;
    margin: 10px 0 0;
    flex-direction: column;
    font-size: 14px;
}

// styles for printing consultant CVs from consultants/:id/cv screen
@page :first {
    margin: 0 0 0 0;
}

@page {
    margin: 0 0;
}

@media print {

    .print-only {
        display: block!important;
    }
    .hide-in-print {
        display: none !important;
    }

    .ant-layout {
        margin: 0 !important;
        padding: 0!important;
    }

    .ant-layout-sider {
        display: none;
    }

    .consultant-container {
        margin: 0 !important;
    }

    .consultant-header {
        display: none!important;
    }

    .consultant-content {
        background: #FFF;
        padding: 40px!important;
        margin: 40px!important;
        box-shadow: 0 6px 10px rgba(0,0,0,0.1)!important;

        .content-card-full {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .cv__section-title {
        font-size: 14px;
    }

    .cv__section-body,
    .cv__list-item {
        font-size: 11px;
    }

    .cv__experience-list {
        break-before: avoid-page;
        break-inside: avoid-page;
    }
}