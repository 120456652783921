@import 'src/containers/app/styles/variables';

.mpa-map-marker {
    position: relative;
    margin-top: -20px;
    background: #5b53f9;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    z-index: 10;
   
    &:hover {
        z-index: 20;
    }

    &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-top-color: #5b53f9;
        border-width: 6px;
        margin-left: -6px;
        margin-top: -1px;
    }

}

.map-map-marker--employer {
    width: 34px;
    height: 34px;
    background: $text-color-light;

    &:after {
        border-top-color: $text-color-light;
    }

}

.mpa-map-marker__info {
    background: #FFF;
    position: absolute;
    bottom: 100%;
    margin-bottom: 10px;
    padding: 20px;
    color: #666;
    z-index: 200;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    * {
        white-space: nowrap;
    }
    
    &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #fff;
        border-width: 10px;
        margin-left: -10px;
    }

    &:before {
        top: 100%;
        left: 0;
        right: 0;
        height: 20;
        position: absolute;
        background: rgba(0,0,0,0);
        content: " ";
    }
}