@import 'src/containers/app/styles/variables';

.mpa-sidebar {
    background: #FFF;
    width: 500px;
    position: fixed;
    top: 0;
    bottom:0;
    right: 0;
    left: auto;
    z-index: 2000;
    box-shadow: -1px 0 5px rgba(0,0,0,0.1);
    transform: translateX(502px);
    transition: transform .2s ease-out;
    overflow: hidden;

    &--open {

        transform: translateX(0);

    }

}

.mpa-sidebar-top-bar {
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.mpa-sidebar-left-column {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 20px;
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    text-align: center;

    .ion-chevron-right {
        font-size: 20px;
    }

    &:hover {
        background-color: color(athens-gray);
    }
}

.mpa-sidebar-close {
    font-size: 32px;
    cursor: pointer;
}

.mpa-sidebar-content {
    animation: sidebar-content-mount .2s ease;
    animation-fill-mode: forwards;
}

@keyframes sidebar-content-mount {
    
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1,
    }
}