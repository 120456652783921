.all-consultants-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-padding-start: 0;

    &__item {
        padding-left: 0;
        margin-top: 20px;

        a:hover {
            text-decoration: none;
            .all-consultants-list__item__text__name {
                text-decoration: underline;
            }
        }
    }

    &__item__preview {
        display: flex;
        flex-direction: row;
    }

    &__item__status {
        position: absolute;
        position: absolute;
        bottom: 0;
        right: -5px;
        width: 15px;
        height: 15px;
        border-radius: 15px;
    }

    &__item__avatar {
        flex-shrink: 0;
    }

    &__item__text {
        padding-left: 15px;
        white-space: nowrap;
        font-size: 11px;
        color: #000;
    }

    &__item__text__name {
        color: #0275d8;
        font-size: 14px;
        font-weight: 600;
    }
    
}
