.mpa-avatar {
	background-size: cover;
	display: inline-block;
    position: relative;
    background-color: #DDD;
	//border: 1px solid #EEE;
    box-sizing: border-box;
    
    &--male {
        background-image: url(~assets/defaultavatar_male.png)
    }

    &--female {
        background-image: url(~assets/defaultavatar_female.png)
    }
}
