.benefit-list-clickable-item{
    white-space: pre-line;
        &:hover{
    cursor: pointer;

        }
}

.benefit-list-row-inactive{
    background-color: #f5f5f5;
}

.benefit-list-row-active{
    background-color: #fff;
}