// a {
//   text-decoration: none;
//   color: #2aacff;


//   &:hover {
//     text-decoration: none !important;
//   }
// }

.btn {

  &:disabled,
  &.disabled {
    opacity: .3,
  }

}