@import 'src/containers/app/styles/variables';

.edit-shifts-modal {
    .warning {
        color: color(roti);
        font-size: 1rem;
    }
    .error {
        color: color(roman);
        font-size: 1rem;
    }
}
