.content-occupation {
   font-size: 18;
   font-weight: 600;
}

.content-specialization-div {
    display: flex;
    margin-top: 5px;
} 

.content-specialization-tag {
    padding: 2px 10px;
    background: #EEE;
    color: #999;
    border-radius: 20px;
    font-size: 14px;
    margin-right: 3px;
}

.content-info-div {
    font-size: 14;
    margin-top: 10px;
    margin-bottom: 10px;
}