.statistics {
    flex: auto;
    padding-bottom: 2.5rem;
    background: #fafafa;

    .content {
        max-width: 81.25rem;
        margin: 0 auto;
    }
}
