@import 'src/containers/app/styles/variables';

// Search bar

.search-bar {
    height: $search-bar-height;
    left: 80;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;

    &__input {
        input {
            border-bottom: 1px solid #eee !important;
            box-sizing: border-box !important;
            width: 100% !important;
            border: none !important;
        }
    }

    &__clear {
        align-items: center;
        bottom: 0;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        justify-content: center;
        padding: 10px;
        position: absolute;
        right: 0;
        top: 0;
    }
}

// Consultants list

.consultants {
    background: #fff;
    flex: auto;
    overflow: scroll;

    &__wrapper {
        display: flex;
        flex-direction: row;
        padding-top: 60px;
    }

    &__content {
        flex: 1 1 0%;
        margin: 0 0 0 300px;
        padding: 30;
        transform: translateX(-0px);
        transition: transform .2s ease;
        width: calc(100% - 300px);
    
        &--query {
            transform: translateX(-150px);
        }

        h3 {
            border-bottom: 1px solid #eee;
            margin: 0;
            padding-bottom: 30px;
        }
    }
}

.consultant {
    display: flex;
    flex-direction: row;

    &__avatar {
        margin-right: 10px;
    }

    &__name {
        font-weight: 700;
    }

    &__occupation {
        color: #999;
        font-size: 14px;
        &--student {
            margin-left: 8px;
            color: rgb(255, 79, 112);
        }
    }

    &__phone {
        display: block;
        font-size: 14px;
    }

    &__tags {
        width: 450px;
    }
}