
.mp-input__required-indicator {
  margin-left: 5px;
}

.mp-input__label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
}
