@import 'src/containers/app/styles/variables';

.table-row-grouped {
    td {
        border: none;
    }
}

.table-row--booked {
    color: #6fbf5d;
}

.table-row--canceled_by_employer, .table-row--canceled_by_admin {
    color: #d9534f;
}

.table-row--paid {
    color: #228B22;
}

.table-row__item__description {
    font-weight: 400;

    
    strong {
        display: block;
        
        &:nth-child(2) {
            font-weight: normal;
            font-size: 14px;  
        }
    }
}

.mpa-employer-shift__booking {
    position: relative;
    &:hover {
        .mpa-employer-shift__booking-popover {
            display: block;
        }
    }
}

.table-row {

    &--consultant-is-sick {
        color: #d9534f;
        // opacity: 0.8;
    }

}

.table-row-shift {

    &--active,
    &:hover {
        background: color(link-water);
        cursor: pointer;
    }

}

.mpa-employer-shift__booking-popover {
    padding: 20px;
    background: #FFF;
    display: none;
    position: absolute;
    right: 100%;
    border-radius: 4px;
    border: 1px solid #EEE;
    box-shadow: 0 2px 1px rgba(0,0,0,0.2);
    color: #333;
    bottom: -35px;
    margin-top: 35px;
    margin-right: 10px;

    &:after {
        content: " ";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -10px;
        width: 10px;
        background: none;
        pointer-events: all;
    }

}