@import 'src/containers/app/styles/variables';

.uploads-modal {
    .success-text {
        color: color(green);
    }

    .footer {
        display: flex;
        justify-content: flex-end;
    }
}
