.banned-reason {
  font-size: 14px;
  position: absolute;
}
.mpa-state-badge {
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  background: #EEE;
  color: #FFF;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
