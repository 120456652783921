
@import 'src/containers/app/styles/variables';

.mp-dashboard__timeline {
    background: $sidebar-color;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.5);
    column-count: 3;
    flex: 2;
    padding: 40px 20px;

    a {
        color: rgba(255, 255, 255, 0.75);
        font-weight: 700;
    }

    .timeline {
        &__event-day {
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: center;
        }
    
        &__event {
            break-inside: avoid-column;
            display: flex;
            flex: 1;
            font-size: 14px;
            margin-bottom: 20px;
            width: 100%;
        
            .mpa-avatar {
                flex-shrink: 0;
                margin-right: 20px;
            }
        }
        
        &__event-header {
            flex: 1;
        }
        
        &__event-details {
            display: flex;
            font-weight: bold;
            justify-content: space-between;
        }
        
        &__event-time {
            color: #999;
            font-weight: normal;
        }
        
        &__event-sickleave {
            color: rgba(255, 255, 255, 0.75);
            font-weight: bold;
            margin-left: 5px;
        }
    }
}

