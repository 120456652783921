@import 'src/containers/app/styles/variables';

.mpa-recruitments {
    padding: 40px;
    flex: 1;
    min-height: 100vh;
    overflow: hidden;
    
    table {
        td {
            vertical-align: middle; 
        }
    }

    .toggle-test-accounts {
        margin: 0;
    }

    .recruitments-view-background {
        background: #FFF;
        margin-top: 40px;
        overflow: scroll;
    }
    
    .recruitments-view-isOld-text {
        font-size: 10px;
    }
    
    .recruitments-avatar {
        margin-right: 3px;
    }
    
    .recruitments-avatars {
        display: flex;
    }
    
    .recruitments-text {
        display: none;
    }
    
    .recruitments-avatar-view:hover .recruitments-text {
        display: block;
    }

    .expiration-warning {
        background-color: color('roman');
    }
}
