@import 'src/containers/app/styles/variables';



.mpa-nav-sidebar__logo {
    background-color: transparent;
    border-right: 1px solid #EEE;
    padding: 0;
    background-image: url(~assets/medpeople_symbol_brand_color.png);
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    height: 64px;;
    color: #FFF;

    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    //filter: invert(.75);

    &--loading {
        background-image: none;
    }
}

.mpa-nav-sidebar__logo-spinner {
    animation: sidebar-logo-spinner .2s ease;
    animation-fill-mode: both;
}

@keyframes sidebar-logo-spinner {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.mpa-nav-sidebar__badge .ant-badge-count {

    right: -15px;
}



.mpa-nav-sidebar__item-title {
    font-size: 9px;

}

