@import 'src/containers/app/styles/variables';

.mpa-tag {

    position: relative;
    padding: 5px 15px;
    padding-right: 45px;
    background: $main-color;
    border-radius: 20px;
    display: inline-block;
    color: #FFF;
    font-weight: 600;
    margin-right: 5px;
    font-size: 14px;
    margin-bottom: 5px;

    &--small {
        font-size: 11px;
        padding: 3px 9px;
        padding-right: 25px;
        .mpa-tag__remove {
            width: 22px;
            padding-right: 3px;
        }
    }

    

}

.mpa-tag__remove {

    width: 35px;
    padding-right: 5px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 30px 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: rgba(0,0,0,0.2);
    }

}