
@import 'src/containers/app/styles/variables';
 
.mp-slider {
    width: 100%;
    max-width: 500px;
    height: 50px;
}

.vertical-slider {
    height: 380px;
    width: 50px;
    border: 1px solid color(santas-grey);
}

.handle {
    font-size: 0.9em;
    text-align: center;
    background-color: color(gallery);
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    color: color(snow);
    cursor: pointer;
}

.handle.active {
    background-color: color(alto);
}

.bar {
    position: relative;
    background: $primary-color;
    border-radius: 5px;
}

.bar.bar-1 {
    background: color(alto);
    border-radius: 5px;
}

.bar.bar-2 {
    background: color(green);
}

.mp-slider .bar {
    top: 20px;
    height: 10px;
}

.mp-slider .handle {
    top: 10px;
    left: -13px;
    width: 26px;
    height: 26px;
    line-height: 26px;
    border-radius: 26px;
}

.vertical-slider .handle {
    left: 1px;
    width: 48px;
    line-height: 50px;
}

.vertical-slider .bar {
    left: 20px;
    width: 10px;
}

#horizontal-0,
#horizontal-1, #horizontal-2, #horizontal-3,
#vertical {
    margin: 20px 10px;
}

#horizontal-2 .bar.bar-2 {
    background: color(alto);
}

