
@import 'src/containers/app/styles/variables';

.mp-switch {
    display: inline-block;
    width: 30px;
    height: 15px;
    background: color(gallery);
    border-radius: 20px;
    box-sizing: border-box;
    position: relative;
    color: color(dusty-gray);
    cursor: pointer;
    transition: all .05s ease;
    box-shadow: 0px 1px 1px color(silver) inset;
    margin-left: 10px;
    top: 5px;
    margin-right: 10px;
}

.mp-switch--on {
    background: lighten($primary-color, 15);
    color: color(snow);
    border-color: $primary-color;
    box-shadow: 0px 1px 1px darken($primary-color, 5) inset;

    .mp-switch__toggle-inner {
        background: $primary-color;
    }
}

.mp-switch--disabled {
    //opacity: 0.5;
    cursor: not-allowed;
    //pointer-events: none;
    filter: saturate(0);
    .mp-switch__toggle-inner {
        background: color(silver);
    }
}

.mp-switch__toggle {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -8px;
    border-radius: 18px;
    box-sizing: border-box;
    z-index: z(1st-floor);
    left: -10px;
    transition: left .1s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mp-switch__toggle--on {
    left: 8px;
}

.mp-switch__toggle-inner {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: color(snow);
    box-shadow: 0px 0px 2px rgba(color(black), 0.2), 0px 1px 1px rgba(color(black), 0.2);
}