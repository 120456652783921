@import 'src/containers/app/styles/variables';

.mp-input__wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 100%;
  }

.mp-input__wrapper--block {
    display: block;
}

.mp-input__wrapper--error {
    .mp-input {
        border-color: color(bittersweet);
    }
}

.mp-input {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 15px;
  border-radius: 3px;
  box-shadow: none;
  margin-bottom: 20px;
  border: 1px solid color(gallery);
  background: color(alabaster);
  outline: none;
  transition: all .1s linear;
  box-sizing: border-box;
  color: $text-color;
  margin-bottom: 20px;
  &:focus {
    //border-color: $primary-color;
    border-color: color(silver);
    background: color(snow);
  }
  &:disabled {
    background: color(concrete);
    color: color(dusty-gray);
  }
}


.mp-input__wrapper-error-message-style-hint {

    &.mp-input__wrapper--error {
        
        .mp-input__error {
            background: color(bittersweet);
        }

    }

    .mp-input__error {
        display: flex;
        align-items: center;
        min-height: 39px;
        font-size: 14px;
        color: color(bittersweet);
        
        position: absolute;
        top: 25px;
        left: 100%;
        margin-left: 10px;
        width: 200px;
        color: color(snow);
        background: color(dove-gray);
        padding: 5px 10px;
        border-radius: 4px;
        transition: background .1s ease;  
    }

}
    
.mp-input__wrapper-error-message-style-error {
    
    &.mp-input__wrapper--error {
        
        .mp-input__error {
            opacity: 1;
        }

    }

    .mp-input__error {
        opacity: 0;
        font-size: 14px;
        margin-top: -17px;
        width: 100%;
        color: color(bittersweet);
        background: none;

        transition: opacity .1s ease;
    }

}
