
.mpa-consultants {
    padding: 40px;
    flex: 1;
    min-height: 100vh;
    table {
        td {
            vertical-align: middle; 
        }
    }

    .header {
        margin-bottom: 2.5rem;
    }

    .heading {
        margin-bottom: .75rem;
    }

    .applied-time {
        background-color: #FFF;
        border-radius: 3px;
        color: #222;
        margin-bottom: 0;
        padding: 0;
    }

    .applied-time.-warn {
        background-color: red;
        color: #FFF;
        padding: 10px;

        .days {
            font-size: .75rem;
            display: block;
        }
    }

    .sum {
        font-size: 2rem;
        font-weight: bold;
        margin-top: 1.5rem;
        text-align: right;
    }
}
