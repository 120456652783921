@import 'src/containers/app/styles/variables';

.profile__list {
    display: flex;
    margin: 0;
    margin-left: 16px;
    padding: 0;
    border-left: 1px solid;
    list-style-type: none;

    .anticon {
        margin: 0 4px 0 16px;
        padding: 4px;
        border-radius: 100%;
        background-color: color(cinderella);
    }
}

.delete-job-btn {
    margin-top: 5px;
}
