@import 'src/containers/app/styles/variables';

.mpa-error-screen {
    height: calc(100% - 6rem);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-size: 42px;
    
    .error-text {
        color:coral;
    }
}