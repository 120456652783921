.form {
        max-width: 37.5rem;
        
        &-item {
            display: block;
            margin-bottom: 2rem;
            padding: 0 .75rem;
        }

        &-item.-inline {
            display: inline-block;
            width: 50%;
        }
    }

    .form-heading {
        font-size: 1.5rem;
        margin: 0;
    }