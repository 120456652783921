@import 'src/containers/app/styles/variables';

.mp-button {
  padding: 12px 15px;
  background: $primary-color;
  border: 2px solid $primary-color;
  color: color(snow);
  font-size: 16px;
  border-radius: 50px;
  border-radius: 5px;
  outline: none;
  margin-top: 10px;
  cursor: pointer;
  transition: all .1s linear;
  font-size: 14px;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  &:hover {
    background: lighten($primary-color, 5);
  }

  &:disabled {
    background: color(silver);
    border-color: color(silver);
    cursor: not-allowed;
  }

}

.mp-button__loader {
  margin-right: 5px;
}

.mp-button--small {
  padding: 10px 15px;
}

.mp-button--block {
  display: block;
  width: 100%;
}

.mp-button--outline {
  background: none;
  border: 2px solid color(snow);
  color: color(snow);
  &:hover, &:focus, &:active {
    background: none;
  }
}

.mp-button--outline-warn {
  background: none;
  border: 2px solid color(french-rose);
  color: color(french-rose);
  transition: all .1s linear;
  &:hover, &:focus, &:active {
    background: none;
  }
  &:hover {
    background: color(french-rose);
    color: color(snow);
  }

  &:disabled {
    background-color: color(snow);
    border-color: color(french-rose);
    opacity: .3;

    &:hover {
      color: color(french-rose);
    }

  }
}

.mp-button--warn {
  background: color(french-rose);
  color: color(snow);
  transition: all .1s linear;
  border-color: color(french-rose);
  
  &:hover, &:focus, &:active {
    background: none;
  }
  &:hover {
    background: color(french-rose);
    color: color(snow);
  }

  &:disabled {
    opacity: .3;

    &:hover {
      color: color(french-rose);
    }

  }
}

.mp-button--confirm {
  background: color(shamrock);
  border-color: color(shamrock);
  text-shadow: 0 1px 1px rgba(color(black),0.1);
  font-size: 16px;
  &:hover {
    background: lighten(color(shamrock), 2);
    border-color: lighten(color(shamrock), 2)
  }
}

.mp-button--outline-color {
  background: none;
  border: 2px solid $primary-color;
  color: $primary-color;
  transition: all .1s linear;
  &:hover, &:focus, &:active {
    background: none;
  }
  &:hover {
    background: $primary-color;
    color: color(snow);
  }
}

.mp-button--outline-color-secondary {
  background: none;
  border: 2px solid $primary-color;
  color: $primary-color;
  &:hover, &:focus, &:active {
    background: none;
  }
}