

.user-marketing-sources {
    flex: auto;
    margin: 2.5rem auto;
    max-width: 81.25rem;

    .heading {
        margin-bottom: 2.5rem;
    }

    .graph {
        padding: 40px;
        margin-bottom: 40px;
        background: rgb(255, 255, 255);
        border-radius: 4px 0px 0px 4px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px;

        .title {
            text-align: center;
            margin-bottom: 40px;
        }
    }
}

.user-marketing-sources.-loading {
    align-items: center;
    display: flex;
    font-size: 1.5rem;
    height: 25rem;
    justify-content: center;
}
