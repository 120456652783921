.consultant-employers {
    .employer {
        display: flex;
    }

    .info {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
    }
}
