.salary-error-list {
    flex: auto;
    margin: 2.5rem;
    min-height: 100vh;

    .heading {
        font-size: 1.75rem;
    }

    .table {
        background: #FFF;
        margin-top: 2.5rem;
    }
}
