@import 'src/containers/app/styles/variables';

.consultants-filter {
    background: #fff;
    border-right: 1px solid #eee;
    box-sizing: border-box;
    color: #888;
    padding: 2rem;
    position: fixed;
    transition: transform .2s ease, opacity .2s ease;
    width: 300px;
    z-index: 20;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - #{$search-bar-height});
    overflow-y: auto;

    &--query {
        transform: translateX(-300px);
    }

    &--hidden {
        opacity: 0;
        z-index: -2000;
    }

    h3 {
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
}

.occupation-filter, .student-filter {
    display: flex;
    flex-direction: column;

    > .heading {
        font-size: 1rem;
        margin: .25rem 0;
    }

    .input {
        margin-right: .5rem;
    }
}

.consultantsfilter__form-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .ant-select {
        display: block;
    }
}

.consultantsfilter__label {
    font-size: 14px;
    margin: 5px 0 2px;
}

.consultantsfilter__amount {
    margin-left: 3px;
    opacity: 0.5;
}

.consultantsfilter__test-accounts {
    margin-bottom: 20px;

    & > .ant-switch {
        margin-right: 8px;
    } 
}

.consultantsfilter__top-accounts {
    margin-bottom: 20px;

    & > .ant-switch {
        margin-right: 8px;
    } 
}

// Last active filters

.last-active-filter {
    margin-bottom: 20px;

    & > label {
        font-size: 14px;
        margin-top: 5;
        margin-bottom: 2px;
    }

    &__label {
        display: block;
        margin: 0;

        input {
            margin-right: 5px;
        }
    }

    &__color {
        border-radius: 4px;
        float: right;
        height: 15px;
        margin-left: 4px;
        position: relative;
        top: 6px;
        width: 15px;
    }
}