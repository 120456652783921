
.mpa-ltvs {
    padding: 40px;
    flex: 1;
    min-height: 100vh;
    overflow: hidden;
    
    table {
        td {
            vertical-align: middle; 
        }
    }

    .ltvs-view-background {
        background: #FFF;
        margin-top: 40px;
        overflow: scroll;
    }

    .ltvs-expanded-row {
        background-color: aqua;
    }
}

.consultantsfilter__test-accounts {
    margin-bottom: 20px;
    width: 210;

    &>.ant-switch {
        margin-right: 8px;
    }
}

.filters-ltvs{
    width: 100%;
    margin-right: 5px !important;
    display: inline-block;
        position: relative;
}

.mp-input__options {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
   // margin-top: 20px;
}