@import 'src/containers/app/styles/variables';

.mpa-loading-screen {
    height: calc(100% - 6rem);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $main-color;
    font-size: 42px;
    .spinner-container {
        width: 42px;
        height: 42px;
        position: relative;
    }
    .fa-spin {
        animation-duration: .5s!important;
        position: absolute;
        top: 0;
        left: 0;
    }
}