
.mp-consultant-overview {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    align-items: flex-start;

    .timeline {
        max-height: 2000px;
        overflow-y: scroll;
        padding: 40px 20px;
        border-radius: 4px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        background: #25252f;

        .ant-list-item {
            position: relative;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 50px;
        }

        .ant-list-item-meta {
            flex: 0;
            position: absolute;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
        }

        .ant-list-item-content {
            justify-content: flex-start;
            flex-direction: column;
            color: rgba(255, 255, 255, 0.5);
        }

        .activity-header {
            color: rgba(255, 255, 255, 0.75);
        }
        
        .activity-timestamp {
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            color: rgba(255, 255, 255, 0.75);

            span {
                text-transform: capitalize;
            }
        }

        .activity-description {
            color: rgba(255, 255, 255, 0.75);
            font-weight: 600;
        }

        .ant-list-split .ant-list-item {
            border: none;
        }
    }

    .timeline-header {
        font-weight: 600;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        color: rgb(255, 255, 255);
    }

    .ant-list-empty-text {
        color: rgb(255, 255, 255);
    }

}