
.mpa-update-employer {
    flex: auto;
    position: relative;
}

.mpa-employer-tab-content {
    //display: flex;
    //flex-wrap: wrap;
    padding: 40px 80px;
    background: #f9f9f9;
    
}

.mpa-employer-data-row {

    border-bottom: 1px solid #EEE;
    padding-bottom: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

}

.mpa-employer-data-title {

    font-weight: 700;

}

.mpa-add-shifts-list__error-row {

    background-color: rgba(255, 0, 0, 0.2);

}