@import 'src/containers/app/styles/variables';

.shifts-calendar {
    flex: auto;
    padding: 40px;
    width:100%;
    overflow: hidden;
}

.mpa-tag {

    position: relative;
    padding: 5px 15px;
    padding-right: 45px;
    background: $main-color;
    border-radius: 20px;
    display: inline-block;
    color: #FFF;
    font-weight: 600;
    margin-right: 5px;
    font-size: 14px;
    margin-bottom: 5px;

    &--small {
        font-size: 11px;
        padding: 3px 9px;
        padding-right: 25px;
        .mpa-tag__remove {
            width: 22px;
            padding-right: 3px;
        }
    }
}
