.retention {
    padding: 2.5rem;

    .heading {
        font-size: 2rem;
    }

    .radiogroup {
        margin-bottom: 1.25rem;
    }

    .client {
        margin-top: 2.5rem;
    }
}
