@import 'src/containers/app/styles/variables';

.home-dashboard {
    display: flex;
    flex: auto;

    padding-bottom: 40px;
    padding-left: $sidebar-width;
    padding-right: $sidebar-width;
    background: #FAFAFA;
    overflow-x: 'hidden';

    .btn-primary {
        background: $primary-color;
    }

    .content {
        max-width: 1300px;
        // min-width: 1300px;
        margin: 0 auto;
        overflow: auto;
    }
}


.mp-new-dashboard__overview-table {
    th {
        padding-left: 20px;
        border-right: 1px solid #EEE;
    }
}
s
.mp-new-dashboard__totals-table {
    th {
        color: #FFF;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }
}

.mp-dashboard__value-text {

    font-size: 22px;
    margin: 2px 0 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.mp-dashboard__value-title {

    font-size: 14px;
    font-weight: 400!important;
    color: #999;

}