


.mp-table {
    box-shadow: 0 1px 5px rgba(0,0,0,0.1);
    border-radius: 4px;
    background: #FFF;
    padding: 0 20px 20px;
}

.mp-table__title {
    padding: 40px 20px 20px;
    //background: #FFF;
    font-size: 24px;
    font-weight: 600;
}

.mp-table__table {
    width: 100%;
    th {
        text-align: left;
        border-top: 1px solid #EEE;
        border-bottom: 1px solid #EEE;
        font-size: 14px;
    }
    th, td {
        padding: 20px 0;
        &:first-child, {
            padding-left: 20px;
        }
        &:last-child {
            padding-right: 20px;
            //text-align: right;
        }
    }
    td {
        border-bottom: 1px solid #EEE;
    }
    tr:last-child td {
        border-bottom: none;
    }
}