.hospArticle {
    margin-bottom: 20;
    padding-bottom: 20;
    border-bottom: 1px solid #EEE;
    p {
        margin: 0
    }
}

.background-check {
    margin-top: 20px;
}

.background-check h5 {
    font-size: 1.25em;
    margin-bottom: 10px;
}

.background-check-list {
    list-style-type: none;
    padding: 0;
}