 .procurements-info  {
    background-color: white;

    &-title {
            margin-bottom: 2px;
            font-size: large;
            font-weight: bold;
        }
    &-title-small{
        margin-bottom: 8px;
        font-size: small;
        font-style: italic;
    }

    &-info-row {
        background-color: white;
        padding: initial;
        border: initial;
        border-color: white;

        .ant-table-cell {
            padding: 20px;
            padding-left: 45px;
            border: initial;
            background-color: white;
            border-bottom: 5px solid #f0f0f0;
            .info-item {
                padding-left: 15px;
                padding-right: 5px;
            }
            .row {
                display: flex;
                flex-direction: row;
            }
        }
    }   
}