.input-modal {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;

    .button {
        align-self: flex-end;
    }
}

.dropdown-button {
    align-self: center;
}

.input-container {
    display: flex;
    flex-direction: row;

    .field {
        display: flex;
        flex: 1;
    }
}