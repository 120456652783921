.order-view {
    flex: auto;
    padding: 2.5rem;
    position: relative;
    min-height: 100%;

    .form {
        max-width: 100rem;
        
        &-item {
            display: block;
            margin-bottom: 2rem;
            padding: 0 .75rem;
        }

        &-item.-inline {
            display: inline-block;
            width: 50%;
        }

    }

    .form-heading {
        font-size: 1.5rem;
        margin: 0;
        font-weight: bold;
    }

    .form-sub-heading {
        font-size: 1.2rem;
        margin: 0;
        font-weight: bold;
    }

    .non-form-item {
        display: block;
        margin-bottom: 2rem;
        margin-left: 8rem;
        padding: 0 .75rem;
    }
}