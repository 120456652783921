.ltv-detail {
    display: flex;
    flex-direction: row;
}

.ltv-detail-info,
.ltv-detail-applications {
    flex: 1;
    position: relative;
}

.ltv-detail-application {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 20px 0;
    margin-bottom: 20px;
}

.ltv-detail-consultant {
    display: flex;
    flex-direction: row;
}

.ltv-detail-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ltv-detail-title {
    font-weight: bold;
}

.profile-hint {
    margin-top: 8px;
    font-size: 12px;
}

.delete-button, .add-consultant-button {
    float: right;
    margin: 0;
}

.add-consultant-button {
    margin-right: 10px;
    float: left;
}

.ltv-booked-title {
    background: #18c746;
    color: #FFF;
    font-weight: 600;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 4px;
}