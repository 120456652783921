
@import 'src/containers/app/styles/variables';

.mpa-edit-trigger {

    color: $primary-color;
    margin-top: 10px;
    display: block;
    font-size: 16px;
    font-weight: 600;

}

.mpa-edit-trigger__icon {

    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: $primary-color;
    color: color(snow);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 2px;

}