@import 'src/containers/app/styles/variables';

.mpa-date-range-picker {
    > .ant-input {
        border: 1px solid color(gallery);
        background: color(alabaster);
        color: $text-color;
    }

    .ant-calendar-range-picker-input {
        font-size: 14px;
    }
}

/*
    Fixes weird antd RangePicker styling issue:
    prev/next buttons are hidden behind input field – this code places them in line with the month name
    (not an issue in the official antd example, could be fixed in a newer version or it clashes with our own styling)
*/
.mpa-date-range-picker__calendar {
    .ant-calendar-header .ant-calendar-prev-year-btn::before,
    .ant-calendar-header .ant-calendar-prev-year-btn::after,
    .ant-calendar-header .ant-calendar-next-year-btn::before,
    .ant-calendar-header .ant-calendar-next-year-btn::after,
    .ant-calendar-header .ant-calendar-prev-month-btn::before,
    .ant-calendar-header .ant-calendar-prev-month-btn::after,
    .ant-calendar-header .ant-calendar-next-month-btn::before,
    .ant-calendar-header .ant-calendar-next-month-btn::after {
        top: 14px;
    }
}