@import 'src/containers/app/styles/variables';

.mp-select {
  position: relative;
}

.mp-select__trigger {
  padding: 15px;
  border: 2px solid color(concrete);
  background: color(alabaster);
  display: inline-block;
  box-sizing: border-box;
  border-radius: 3px;
  transition: all .1s linear;
  cursor: pointer;
  position: relative;

  &--open {
    border-color: lighten($primary-color, 25);
    background: color(alabaster);
    box-shadow: 0 1px 4px rgba(color(black),0.1);
  }

  &--disabled {
    background: color(gallery);
    color: color(dove-gray);
    border: 1px solid color(concrete);
    cursor: not-allowed;
  }
}

.mp-select__trigger-icon {
  margin-left: 10px;
  float: right;
}

.mp-select__options {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 200px;
  //min-width: 100%;
  max-height: 400px;
  overflow: hidden;
  background: color(snow);
  border: 1px solid color(concrete);
  z-index: 900;
  border-radius: 4px;
  box-shadow: 0 7px 25px rgba(color(black), 0.1);
  animation: select-options-mount .1s ease;
  animation-fill-mode: forwards;

}

@keyframes select-options-mount {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.mp-select__options-list {
  overflow: auto;
  height: 100%;
  max-height: 400 - 72px;
}

.mp-select__option {
  padding: 15px 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  position: relative;

  &--is-selected {
    color: $primary-color;
  }

  &--is-highlighted {
    background: color(alabaster);
  }

  &--is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }

}

.mp-select__filter {
  padding: 10px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.mp-select__filter-input {
  margin-bottom: 0;
  padding: 15px;
  box-sizing: border-box;
  width: auto;
  display: block;
}