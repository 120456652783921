
.mpa-employers {
    overflow: scroll;

    table {
        td { 
            vertical-align: middle; 
        }
    }
}

.employers-container {
    flex: auto;
}