.create-employer {
    flex: auto;
    padding: 2.5rem;
    position: relative;
    min-height: 100%;

    .heading {
        font-size: 2rem;
        margin: .75rem 0;
    }

    .spinner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        display: flex;
        justify-content: center;
        align-items: 'center';
        background-color: rgba(255, 255, 255, 0.5);
    }

    .copy-images {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        max-width: 600px;

        .heading {
            font-size: 1rem;
            margin-bottom: .75rem;
        }
    }

    .form {
        max-width: 37.5rem;
        
        &-item {
            display: block;
            margin-bottom: 2rem;
            padding: 0 .75rem;
        }

        &-item.-inline {
            display: inline-block;
            width: 50%;
        }
    }

    .form-heading {
        font-size: 1.5rem;
        margin: 0;
    }

    .copy-progress {
        .list {
            padding: .75rem;
            
            .item {
                align-items: center;
                display: flex;
                margin-bottom: .75rem;

            }
       
            .text {
                margin: 0 .5rem;
            }
        }
    }

    .button {
        margin: 0 0 2rem .75rem;
    }

    .alert {
        display: absolute;
        bottom: 2.5rem;
        width: 100%;
        max-width: 36rem;
        margin-left: 2.25rem;
        z-index: 2;
    }
}
