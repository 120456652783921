.uploads-table {
    .actions-column {
        display: flex;
        justify-content: space-around;
    }
}

.consultant-content {
    .upload-button {
        float: right;
        margin-bottom: 1rem;
    }
}
