.mpa-state-badge {
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    background: #EEE;
    color: #FFF;
    border-radius: 4px;
    text-align: center;
    display: inline-block;

    &--large {
        font-size: 22px;
    }

    &--pending {
        background: #5b53f9;
    }

    &--activated {
        background: #71e0aa;
    }

    &--removed {
        background: #d9534f;
    }
}