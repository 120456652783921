

.simple-overview {

    .anticon svg {
        display: inline-block !important;
    }

    .mau-consultants,
    .mau-employers,
    .engagement-funnel {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            width: 60px;
            height: 1px;
            background-color: #fff;
            top: -25px;
        }

    }

    .mau-consultants {

        &:before {
            left: 170px;
            transform: rotate(-50deg);
        }

    }

    .mau-employers {

        &:before {
            right: 170px;
            transform: rotate(50deg);
        }

    }

    .engagement-funnel {

        &:before {
            right: 0;
            left: 0;
            margin: auto;
            transform: rotate(90deg);
            width: 35px;
            top: 10px;
        }

    }

    .minor-value-circle {
        font-weight: 600;
        font-size: 20px;
        line-height: 60px;
        text-align: center;
        margin-right: 10px;
        border: 1px solid;
        width: 60px;
        height: 60px;
        border-radius: 100%;
    }

    .ant-tabs-nav-container-scrolling {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .ant-tabs-tab {
        padding: 10px !important; 
        // font-size: 22px !important;
        // line-height: 22px !important;
        text-transform:  capitalize;
    }

    .ant-tabs-tab-active {
        // font-weight: 600 !important;
    }

    .ant-tabs-tab-next,
    .ant-tabs-tab-prev {
        // background-color: #25252f;
        border-radius: 100%;
        width: 50px !important;
        color: #fff; // #25252f;
        // color: #fff !important;

        .ant-tabs-tab-prev-icon-target,
        .ant-tabs-tab-next-icon-target {
            margin-top: -5px;
            font-size: 24px !important;
        }
    }

    .ant-tabs-tab-next {
        padding-right: 10px;
    }

}