.order-details-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    padding: 24px;
    
    .order-details-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .ant-typography {
            margin: 0;
        }
    }
    
    .detail-item {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    
    .roles-section {
        .ant-typography {
            margin-bottom: 16px;
        }
        
        .role-item {
            background: #fafafa;
            border-radius: 6px;
            padding: 16px;
            margin-bottom: 16px;
            
            &:last-child {
                margin-bottom: 0;
            }
            
            .role-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;
            }
            
            .shift-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 12px;
                background: white;
                border-radius: 4px;
                margin-bottom: 8px;
                
                &:last-child {
                    margin-bottom: 0;
                }
                
                &:hover {
                    background: #f5f5f5;
                }
            }
        }
    }
    
    .ant-divider {
        margin: 24px 0;
    }
}