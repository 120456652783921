@import 'src/containers/app/styles/variables';

.mpa-tabs {
    border-bottom: 1px solid #EEE;
    padding: 0 $sidebar-width;
    background: #FFF;
}

.mpa-tabs__list {
    display: flex;
}

.mpa-tabs__tab {
    padding: 20px 0;
    margin-right: 40px;
    padding-top: 15px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    border-top: 5px solid transparent;
    color: #999;

    &--active {
        border-top-color: #5b53f9;
        color: #333;
        //background: #fcfcfc;
    }
}