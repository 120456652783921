
.month-overview {
    background: #fafafa;
    margin-bottom: 40px;
    
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h3 {
            margin: 20px 0 20px;
        }

        a {
            font-size: 18px;
            font-weight: bold;
        }
    }

    &__content {
        display: flex;
    }
}